<template>
  <div>
    <OmologazioniList
      :fromEsito="true"
      :id_societa="id_societa"
      :id_omologatore="id_omologatore"
      :tipologie="tipologie"
      :id_disciplina="id_disciplina"
      :rowstoskip="rowstoskip"
      :fetchrows="fetchrows"
      :sortcolumn="sortcolumn"
      :sortorder="sortorder"
      :currentpage="currentpage"
      :tutteLeStagioni="tutteLeStagioni"
      :denominazione_societa="denominazione_societa"
      :denominazione_omologatore="denominazione_omologatore"
      @resetFilters="resetFilters"
      @resetRowsToSkip="resetRowsToSkip"
      @setFetchRows="setFetchRows"
      @setCurrentPage="setCurrentPage"
      @setSortOrder="setSortOrder"
      @setDisciplina="setDisciplina"
      @setIdSocieta="setIdSocieta"
      @setIdOmologatore="setIdOmologatore"
    />
  </div>
</template>

<script>
import OmologazioniList from "@/components/components-fit/omologazioni/OmologazioniList.vue";

import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "RihciesteOmologazioniList",
  components: {
    OmologazioniList,
  },
  setup() {
    const store = useStore();

    const resetFilters = () => {
      store.commit("resetFiltersEsitoOmologazione");
    };

    const resetRowsToSkip = () => {
      store.commit("setrowstoskipEsitoOmologazione");
    };

    const setFetchRows = (e) => {
      store.commit("setfetchrowsEsitoOmologazione", e);
    };
    const setCurrentPage = (page) => {
      store.commit("setcurrentpageEsitoOmologazione", page);
    };
    const setSortOrder = (columnName, order) => {
      store.commit("setsortcolumnEsitoOmologazione", columnName);
      store.commit("setsortorderEsitoOmologazione", order);
    };

    //FITLRI
    const setDisciplina = (value) => {
      store.commit("setdisciplineEsitoOmologazione", value);
    };

    const setIdSocieta = ({ value, name }) => {
      store.commit("setid_societaEsitoOmologazione", value);
      store.commit("setdenominazioneSocietaEsitoOmologazione", name);
    };

    const setIdOmologatore = ({ value, name }) => {
      store.commit("setid_omologatoreEsitoOmologazione", value);
      store.commit("setdenominazioneOmologatoreEsitoOmologazione", name);
    };

    return {
      id_societa: computed(() => store.getters.id_societaEsitoOmologazione),
      id_omologatore: computed(
        () => store.getters.id_omologatoreEsitoOmologazione
      ),
      tipologie: computed(() => store.getters.tipologieEsitoOmologazione),
      id_disciplina: computed(() => store.getters.disciplineEsitoOmologazione),
      denominazione_societa: computed(
        () => store.getters.denominazioneSocietaEsitoOmologazione
      ),
      denominazione_omologatore: computed(
        () => store.getters.denominazioneOmologatoreEsitoOmologazione
      ),
      rowstoskip: computed(() => store.getters.rowstoskipEsitoOmologazione),
      fetchrows: computed(() => store.getters.fetchrowsEsitoOmologazione),
      sortcolumn: computed(() => store.getters.sortcolumnEsitoOmologazione),
      sortorder: computed(() => store.getters.sortorderEsitoOmologazione),
      currentpage: computed(() => store.getters.currentpageEsitoOmologazione),
      tutteLeStagioni: computed(
        () => store.getters.tutteLeStagioniEsitoOmologazione
      ),
      resetFilters,
      resetRowsToSkip,
      setFetchRows,
      setCurrentPage,
      setSortOrder,
      setDisciplina,
      setIdOmologatore,
      setIdSocieta,
    };
  },
});
</script>
